import React from "react";
import "../style/widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import moment from "moment/moment";

const Widget = ({ type }) => {
  const [products, setProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filteredBulan, setFilteredBulan] = useState([]);
  const [filteredTanggal, setFilteredTanggal] = useState([]);
  const [filteredTahun, setFilteredTahun] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    await axios
      .get(`https://subdomain.sudbalam.com/api/dataterima`)
      .then(({ data }) => {
        setProducts(data);
        setIsLoaded(true);
      });
  };

  const tahun = new Date().getFullYear();
  const month = new Date().getMonth();
  const monthName = new Date(0, month).toLocaleString("id-ID", {
    month: "long",
  });
  const tanggal = new Date().getDate();

  console.log(monthName, "montnmae");

  useEffect(() => {
    setFilteredTahun(
      products.filter(
        (product) => product?.tlg_pembayaran.split(" ")[3] === String(tahun)
      )
    );
  }, [products]);

  useEffect(() => {
    setFilteredBulan(
      products.filter(
        (product) =>
          product?.tlg_pembayaran.split(" ")[2] === monthName &&
          product?.tlg_pembayaran.split(" ")[3] === String(tahun)
      )
    );
  }, [products]);

  useEffect(() => {
    setFilteredTanggal(
      products.filter(
        (product) =>
          product?.tlg_pembayaran.split(" ")[1] === String(tanggal) &&
          product?.tlg_pembayaran.split(" ")[2] === monthName &&
          product?.tlg_pembayaran.split(" ")[3] === String(tahun)
      )
    );
  }, [products]);

  let data;

  // const past = new Date(filteredTahun[0]?.nik);

  console.log(filteredTanggal);

  // console.log({ past });
  // console.log(typeof filteredTahun[0]?.tlg_pembayaran, "bulan");
  // console.log(tahun);

  // const tanggalString = "Jumat, 3 Maret 2023";

  // Parsing tanggal menggunakan objek Date
  const tanggalL = products[4000]?.tlg_pembayaran.split(" ")[1];

  // Mendapatkan tahun dari tanggal
  // const tahunN = tanggalL.getFullYear();
  // const tahunN = tanggalL[3]

  console.log(tanggalL, "bulan lagi"); // Output: 2023
  console.log(tanggal, "bulan lagi nih"); // Output: 2023
  // console.log(tahunN, 'ini nih'); // Output: 2023
  // console.log(filteredTahun[0]);

  useEffect(() => {
    const nikDitemukan = {};
    const dataDenganNIKSama = [];
    products.forEach((item) => {
      const nik = item.nik_alm;
      if (nikDitemukan[nik]) {
        dataDenganNIKSama.push(nik);
      } else {
        nikDitemukan[nik] = true;
      }
    });
    console.log(dataDenganNIKSama);
  }, [products]);

  switch (type) {
    case "jumlah_kematian":
      data = {
        title: "JUMLAH PENCAIRAN DANA SEPANJANG WAKTU",
        isMoney: false,
        amount: products.length,
        isJumlah: true,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "anggaran_kematian":
      data = {
        title: "ANGGARAN DANA SANTUNAN KEMATIAN KELUAR SEPANJANG WAKTU",
        isMoney: true,
        amount: (products.length * 1000000).toLocaleString('id-ID'),
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "kematian_tahun":
      data = {
        title: "JUMLAH DISALURKAN TAHUN INI",
        isMoney: false,
        isJumlah: true,
        amount: filteredTahun.length,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "kematian_bulan":
      data = {
        title: "JUMLAH DISALURKAN BULAN INI",
        isMoney: false,
        isJumlah: true,
        amount: filteredBulan.length,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "kematian_Hari":
      data = {
        title: "JUMLAH DISALURKAN HARI INI",
        isMoney: false,
        isJumlah: true,
        amount: filteredTanggal.length,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  if (!isLoaded) {
    return <div className="loading-widget"></div>;
  } else {
    return (
      <div className="widget">
        <div className="left">
          <span className="title">{data.title}</span>
          <span className="counter">
            {data.isMoney && "Rp. "} {data.amount}
            {data.isJumlah && " Data"}
          </span>
          <span className="link"></span>
        </div>
        <div className="right">
          <div className="percentage positive"></div>
          {data.icon}
        </div>
      </div>
    );
  }
};

export default Widget;
